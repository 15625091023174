import React, { useState, useEffect, useRef } from 'react';
import './leaderboard.css';
import TimerLead from '../universal/timerLeaderboard';

const formatNumber = (num) => {
  if (Math.abs(num) >= 1_000_000) {
    return (num / 1_000_000).toFixed(2) + 'M';
  } else if (Math.abs(num) >= 1_000) {
    return Math.round(num / 1_000) + 'K';
  } else {
    return num.toString();
  }
};

const Leaderboard = ({ navbarHeight }) => {
    const section1Ref = useRef(null);
    const button1Ref = useRef(null);
    const section3Ref = useRef(null);
    const btnSubmitRef = useRef(null);

    const [bgHeight, setBgHeight] = useState(0);
    const [buttonWidth, setButtonWidth] = useState(0);
    const [inputHeight, setInputHeight] = useState(0);
    const [inputWidth, setInputWidth] = useState(0);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);  // Store all data for UID lookup
    const [loading, setLoading] = useState(true);
    const [uidInput, setUidInput] = useState('');
    const [userPosition, setUserPosition] = useState(null);
    const [uid, setUid] = useState('');
    const [lastUpdate, setLastUpdate] = useState(null);

    useEffect(() => {
        if (section1Ref.current) {
            const section1Height = section1Ref.current.offsetHeight;
            setBgHeight(section1Height + navbarHeight);
        }

        if (button1Ref.current) {
            const button1Width = button1Ref.current.offsetWidth;
            setButtonWidth(button1Width);
        }

        if (btnSubmitRef.current) {
            const btnSubmitHeight = btnSubmitRef.current.offsetHeight;
            const btnSubmitWidth = btnSubmitRef.current.offsetWidth;
            setInputHeight(btnSubmitHeight);
            setInputWidth(2 * btnSubmitWidth);
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://cryptoxnft.org/api/data');
                const result = await response.json();
                setAllData(result); // Store all data for UID lookup
                const filteredData = result.slice(0, 30); // Top 30 for the leaderboard
                setData(filteredData);
                setLastUpdate(result[0]?.last_update);
            } catch (error) {
                console.error('Error fetching leaderboard data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const scrollToSection = (sectionRef) => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleUidSearch = () => {
        const userEntry = allData.find((entry) => entry.uid === uidInput); // Search in allData
        if (userEntry) {
            const position = allData.indexOf(userEntry) + 1; // index + 1 because arrays are 0-based
            setUserPosition({
                uid: userEntry.uid,
                position,
                volume: userEntry.trading_volume_usdt,
                email: userEntry.email,
            });
        } else {
            setUserPosition(null);
        }
        setUid(uidInput); // Set the UID after pressing the button
    };

    const prizeList = [
        '$5,000', '$4,000', '$3,000', '$2,500', '$2,000', '$1,500', '$1,200', '$1,000', '$800', '$500', 
        '$400', '$350', '$300', '$300', '$250', '$250', '$200', '$200', '$150', '$150',
        '$120', '$120', '$100', '$100', '$100', '$80', '$80', '$60', '$50', '$50'
    ];

    if (loading) {
        return <p>Loading leaderboard data...</p>;
    }

    const tableRows = data.map((entry, index) => {
        let prize = prizeList[index] || '$0'; // Get the prize or default to $0
        let vipTextStyle = { 
            fontWeight: 'bold' 
        };
        let style = { 
            color: '#0bf511', 
            textShadow: '0px 0px 15px #0bf511', 
            fontWeight: 'bold' 
        };

        if (index < 10) {
            vipTextStyle = { 
                color: 'gold', 
                textShadow: '0px 0px 15px gold', 
                fontWeight: 'bold' 
            };
        } else if (index < 20) {
            vipTextStyle = { 
                color: 'silver', 
                textShadow: '0px 0px 15px silver', 
                fontWeight: 'bold' 
            };
        } else {
            vipTextStyle = { 
                color: '#cd7f32', 
                textShadow: '0px 0px 15px #cd7f32', 
                fontWeight: 'bold' 
            };
        } 

        return (
            <tr key={entry.id}>
                <td className='leaderboard-position'>{index + 1}</td>
                <td className='leaderboard-uid'>{entry.uid}</td>
                <td className='leaderboard-volume'>${entry.trading_volume_usdt.toLocaleString()}</td>
                <td className='leaderboard-email'>{entry.email !== "email missing" ? entry.email : 'N/A'}</td>
                <td className='leaderboard-prize' style={style}>
                    {prize}
                    <span style={vipTextStyle}> + VIP</span>
                </td>
            </tr>
        );
    });


    return (
        <div className='main-content'>
            <img 
                className='section1-bg' 
                src='./assets/images/backgrounds/bg1.png' 
                style={{ height: `${bgHeight}px` }} 
                alt="Background" 
            />
            <div className='leaderboard-section1' ref={section1Ref}>
                <div className="leaderboard-section1-header">
                    <h1><span>- $50,000 -</span></h1>
                    <h1>BINGX TRADING COMPETITION</h1>
                </div>
                <TimerLead/>
                <div className='leaderboard-top3'>
                        <div className='leaderboard-card first'>
                            <h1>1ST</h1>
                            <h2>$5,000</h2>
                            <div className='card-uid'>
                                <p style={{fontWeight:'bold'}}>UID</p>
                                <p style={{color:'#FFD700', fontWeight:'bold', marginTop:'5px', textShadow: '0px 0px 15px #FFD700'}}>{data[0]?.uid || 'UID'}</p>
                            </div>
                            <div className='card-volume'>
                                <p style={{fontWeight:'bold'}}>VOLUME</p>
                                <p style={{color:'#FFD700', fontWeight:'bold', marginTop:'5px', textShadow: '0px 0px 15px #FFD700'}}>${data[0]?.trading_volume_usdt ? formatNumber(data[0].trading_volume_usdt) : 'VOLUME'}</p>
                            </div>
                        </div>
                        <div className='leaderboard-card second'>
                            <h1>2ND</h1>
                            <h2>$4,000</h2>
                            <div className='card-uid'>
                                <p style={{fontWeight:'bold'}}>UID</p>
                                <p style={{color:'#afafaf', fontWeight:'bold', marginTop:'5px', textShadow: '0px 0px 15px #afafaf'}}>{data[1]?.uid || 'UID'}</p>
                            </div>
                            <div className='card-volume'>
                                <p style={{fontWeight:'bold'}}>VOLUME</p>
                                <p style={{color:'#afafaf', fontWeight:'bold', marginTop:'5px', textShadow: '0px 0px 15px #afafaf'}}>${data[1]?.trading_volume_usdt ? formatNumber(data[1].trading_volume_usdt) : 'VOLUME'}</p>
                            </div>
                        </div>
                        <div className='leaderboard-card third'>
                            <h1>3RD</h1>
                            <h2>$3,000</h2>
                            <div className='card-uid'>
                                <p style={{fontWeight:'bold'}}>UID</p>
                                <p style={{color:'#CD7F32', fontWeight:'bold', marginTop:'5px', textShadow: '0px 0px 15px #CD7F32'}}>{data[2]?.uid || 'UID'}</p>
                            </div>
                            <div className='card-volume'>
                                <p style={{fontWeight:'bold'}}>VOLUME</p>
                                <p style={{color:'#CD7F32', fontWeight:'bold', marginTop:'5px', textShadow: '0px 0px 15px #CD7F32'}}>${data[2]?.trading_volume_usdt ? formatNumber(data[2].trading_volume_usdt) : 'VOLUME'}</p>
                            </div>
                        </div>
                </div>
                <div className='leaderboard-section1-buttons'>
                    <a href='https://bingx.com/partner/Cryptoxnft' target='blank'><button ref={button1Ref} className='button-dark'>JOIN NOW</button></a>
                    <button style={{ width: `${buttonWidth}px`}} onClick={() => scrollToSection(section3Ref)} className='button-dark'>PRIZES</button>
                </div>
            </div>

            <div className='leaderboard-section2'>
                <div className='leaderboard-section2-fade'>
                    <h1 className='leaderboard-section2-header'>LEADERBOARD</h1>
                    <p className='leaderboard-section2-condition'>ONLY USERS WHO USED <span>"CRYPTOXNFT"</span> PROMO CODE ARE SHOWN</p>

                    <div className="uid-lookup">
                        <h2 className='uid-lookup-header'>FIND YOUR POSITION</h2>
                        <div className='uid-lookup-input'>
                            <div className='uid-lookup-input-top'>
                                <input
                                    type="text"
                                    value={uidInput}
                                    onChange={(e) => setUidInput(e.target.value)}
                                    placeholder="Enter your UID"
                                    style={{height: `${inputHeight}px`, width: `${inputWidth}px`}}
                                />
                                <button ref={btnSubmitRef} onClick={handleUidSearch} className='button-dark'>Find</button>
                            </div>
                        </div>
                        <div className='uid-lookup-input-bottom'>
                            {userPosition && (
                                <div className="user-position-info">
                                    <div className='user-position-info-left'>
                                        <p><strong>Position:</strong> {userPosition.position}</p>
                                        <p><strong>UID:</strong> {userPosition.uid}</p>                                       
                                    </div>
                                    <div className='user-position-info-right'>
                                        <p><strong>Volume:</strong> ${userPosition.volume.toLocaleString()}</p>
                                            <p><strong>Email:</strong> {userPosition.email}</p> 
                                    </div>                                       
                                </div>
                            )}
                            {!userPosition && uid && <p>User not found in the leaderboard.</p>}
                        </div>
                    </div>

                    <div className='leaderboard-section2-container'>
                        <div className='last-update'>
                            {lastUpdate ? (
                                <p>Last Update: {new Date(lastUpdate).toLocaleString()}</p>
                            ) : (
                                <p>Last Update: N/A</p>
                            )}
                        </div>                        
                        <div className='leaderboard-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='leaderboard-position'>POSITION</th>
                                        <th className='leaderboard-uid'>UID</th>
                                        <th className='leaderboard-volume'>VOLUME</th>
                                        <th className='leaderboard-email'>EMAIL</th>
                                        <th className='leaderboard-prize'>PRIZE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='leaderboard-section2-calculation'>
                        <p className='leaderboard-section2-calc-main'>HOW IS THE VOLUME CALCULATED?</p>
                        <p className='leaderboard-section2-calc'>100$ trade with 10x leverage is $100 x 10 = $1,000</p>
                    </div>                    
                </div>
            </div>

            <div className='leaderboard-section3' ref={section3Ref}>
                <h1>CASH PRIZES</h1>
                <div className='prizes-container'>
                    <div className='prizes-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th>PLACE</th>
                                    <th>PRIZE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>🥇 <span style={{color:'#FFD700', fontWeight:'bold', textShadow: '0px 0px 15px #FFD700'}}>1st</span></td><td style={{color:'#FFD700', fontWeight:'bold', textShadow: '0px 0px 15px #FFD700'}}>$5,000</td></tr>
                                <tr><td>🥈 <span style={{color:'#afafaf', fontWeight:'bold', textShadow: '0px 0px 15px #afafaf'}}>2nd</span></td><td style={{color:'#afafaf', fontWeight:'bold', textShadow: '0px 0px 15px #afafaf'}}>$4,000</td></tr>
                                <tr><td>🥉 <span style={{color:'#CD7F32', fontWeight:'bold', textShadow: '0px 0px 15px #CD7F32'}}>3rd</span></td><td style={{color:'#CD7F32', fontWeight:'bold', textShadow: '0px 0px 15px #CD7F32'}}>$3,000</td></tr>
                                <tr><td>4th</td><td>$2,500</td></tr>
                                <tr><td>5th</td><td>$2,000</td></tr>
                                <tr><td>6th</td><td>$1,500</td></tr>
                                <tr><td>7th</td><td>$1,200</td></tr>
                                <tr><td>8th</td><td>$1,000</td></tr>
                                <tr><td>9th</td><td>$800</td></tr>
                                <tr><td>10th</td><td>$500</td></tr>
                                <tr><td>11th</td><td>$400</td></tr>
                                <tr><td>12th</td><td>$350</td></tr>
                                <tr><td>13th - 14th</td><td>$300</td></tr>
                                <tr><td>15th - 16th</td><td>$250</td></tr>
                                <tr><td>17th - 18th</td><td>$200</td></tr>
                                <tr><td>19th - 20th</td><td>$150</td></tr>
                                <tr><td>21st - 22nd</td><td>$120</td></tr>
                                <tr><td>23rd - 25th</td><td>$100</td></tr>
                                <tr><td>26th - 27th</td><td>$80</td></tr>
                                <tr><td>28th</td><td>$60</td></tr>
                                <tr><td>29th - 30th</td><td>$50</td></tr>
                            </tbody>
                        </table>
                    </div>  
                </div>
                {/* <div className='leaderboard-section1-buttons'>
                    <a href='https://bingx.com/partner/Cryptoxnft' target='blank'><button ref={button1Ref} className='button-dark'>JOIN NOW</button></a>
                </div>                 */}

                <h1>VIP PRIZES</h1>
                <div className='prizes-container'>
                    <div className='prizes-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th>PLACE</th>
                                    <th>PRIZE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td style={{color:'#FFD700', fontWeight:'bold', textShadow: '0px 0px 15px #FFD700'}}> 1st - 10th</td><td style={{color:'#FFD700', fontWeight:'bold', textShadow: '0px 0px 15px #FFD700'}}>LIFETIME</td></tr>
                                <tr><td style={{color:'#afafaf', fontWeight:'bold', textShadow: '0px 0px 15px #afafaf'}}>11th - 20th</td><td style={{color:'#afafaf', fontWeight:'bold', textShadow: '0px 0px 15px #afafaf'}}>MONTHLY</td></tr>
                                <tr><td style={{color:'#CD7F32', fontWeight:'bold', textShadow: '0px 0px 15px #CD7F32'}}>21st - 30th</td><td style={{color:'#CD7F32', fontWeight:'bold', textShadow: '0px 0px 15px #CD7F32'}}>WEEKLY</td></tr>
                            </tbody>
                        </table>
                    </div>  
                </div>                

                <div className='leaderboard-section1-buttons'>
                    <a href='https://bingx.com/partner/Cryptoxnft' target='blank'><button ref={button1Ref} className='button-dark'>JOIN NOW</button></a>
                </div>
                <div className='uid-tutorial'>
                    <h1>HOW TO GET YOUR UID?</h1>
                    <video src=".././assets/videos/leaderboard.mp4" loop={true} muted={true} autoPlay={true} playsInline={true} webkit-playsinline="true"/>
                </div>
            </div>
        </div>
    );
}

export default Leaderboard;
