import React, { useState, useRef, useEffect } from "react";
import copy from '../../vip/copy.svg';
import Footer from '../../../universal/footer';
import '../../../universal/payment.css';
import useDelayedHeightCalculation from "../../../universal/useDelayedHeightCalculation";

const IndicatorPayment = ({ navbarHeight }) => {

    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this

    useEffect(() => {
        setBgHeight(section1Height + navbarHeight);
    }, [navbarHeight, section1Height]);

    const handleCopyToClipboard = () => {
        const addressText = document.getElementById('address').innerText;
        navigator.clipboard.writeText(addressText).then(() => {
            alert('Address copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const paymentInfo = {
        USDT: {
            address: 'TEPJARNvzP9yCWfDhpkNeXm8dUaGg6P8f9',
            qrCode: '../.././assets/images/services/vip/USDT.JPG',
            text: 'SEND USDT ONLY',
            network: 'NETWORK: TRC20',
            price: '199 USDT'
        }
    };

    return (
        <div className="main-content">
            <img
                src='../.././assets/images/backgrounds/bg1.png'
                className='payment-section1-bg'
                style={{ height: `${bgHeight}px` }}
                alt="Background"
            />
            <div className="payment-main">
                <div className="payment-section1" ref={section1Ref}>
                    <h1 className="payment-title">AI INDICATOR</h1>

                    <div className="payment-details">
                        <div className="payment-details-glass">
                            <h2 className="payment-price" style={{color: '#fff', marginBottom:'3vh'}}>{paymentInfo.USDT.price}</h2>
                            <img src={paymentInfo.USDT.qrCode} alt="USDT QR Code" className="payment-qr" />
                            <div className="payment-details-address">
                                <p id="address">{paymentInfo.USDT.address}</p>
                            </div>
                            <button onClick={handleCopyToClipboard}>
                                <img src={copy} alt="copy icon" className="payment-copy-icon" />COPY
                            </button>
                            <div className="payment-subheader">
                                <h2>{paymentInfo.USDT.text}</h2>
                                <div className="payment-divider"></div>
                                <h2>{paymentInfo.USDT.network}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="payment-section2">
                    <h1>REDEEM YOUR INDICATOR</h1>
                    <h2>SEND A SCREENSHOT OF YOUR PAYMENT</h2>
                    <a href="https://t.me/cryptoxnftsignals" target="blank"><button className="button-dark">SEND</button></a>
                </div>
            </div>
            <div className="footer"><Footer /></div>
        </div>
    );
};

export default IndicatorPayment;
