import React, { useState, useEffect, useRef } from 'react';
import Footer from '../../universal/footer';
import { Link } from 'react-router-dom';
import './blofinguide.css';

const BlofinGuide = ({ navbarHeight }) => {
    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    useEffect(() => {
        if (section1Ref.current) {
            const section1Height = section1Ref.current.offsetHeight;
            setBgHeight(section1Height + navbarHeight);
        }
    }, [navbarHeight]);

    return (
        <div className='main-content'>
            <img
                src='../.././assets/images/backgrounds/bg1.png'
                className='copy-section1-bg'
                style={{ height: `${bgHeight}px` }}
                alt="Background"
            />
            <div className='blofin-guide-main'>
                <div className='blofin-guide-section1' ref={section1Ref}>
                    <h1>COPY TRADING GUIDE</h1>
                    <h2>LET OTHERS DO THE WORK FOR YOU</h2>
                    <div className='blofin-trader-name-box'>
                        <h2 className='blofin-trader-name'>
                            <span className='blofin-trader-name-span'>CRYPTOXNFT TEAM</span>
                        </h2>
                    </div>
                    <h3>FOLLOW THE STEPS BELOW, DEPOSIT ANY AMOUNT OF MONEY AND EXPECT AROUND <span>2X - 3X</span> IN <span>20 - 30 DAYS</span></h3>
                </div>

                <div className="blofin-aff-section2">
                    <div className="blofin-aff-section2-steps">

                        <div className="blofin-aff-step">
                            <h2 className="blofin-aff-steps-header">STEP 1</h2>
                            <h3>Click the button below</h3>
                            <a href='https://blofin.com/copy-trade/details/5244115596' target='blank'><button className='blofin-button-dark-steps'>JOIN</button></a>
                        </div>

                        <div className="blofin-aff-step">
                            <h2 className="blofin-aff-steps-header">STEP 2</h2>
                            <h3>Click "COPY"</h3>
                            <div className='blofin-aff-guide-image'><img src='../.././assets/images/affiliate/guide/step2.png'/></div>      
                        </div>   

                        <div className="blofin-aff-step">
                            <h2 className="blofin-aff-steps-header">STEP 3</h2>
                            <h3>Click "Fixed Amount"</h3>
                            <div className='blofin-aff-guide-image'><img src='../.././assets/images/affiliate/guide/step3.png'/></div>      
                        </div> 

                        <div className="blofin-aff-step">
                            <h2 className="blofin-aff-steps-header">STEP 4</h2>
                            <h3>Decide how much to invest</h3>
                            <div className='blofin-aff-guide-image'><img src='../.././assets/images/affiliate/guide/step4.png'/></div>      
                        </div> 

                        <div className="blofin-aff-step">
                            <h2 className="blofin-aff-steps-header">STEP 5</h2>
                            <h3>Decide order size</h3>
                            <div className='blofin-aff-guide-image'><img src='../.././assets/images/affiliate/guide/step5.png'/></div>      
                        </div>   

                        <div className="blofin-aff-step">
                            <h2 className="blofin-aff-steps-header">STEP 6</h2>
                            <h3>Accept the ToS</h3>
                            <div className='blofin-aff-guide-image'><img src='../.././assets/images/affiliate/guide/step6.png'/></div>      
                        </div>  

                        <div className="blofin-aff-step">
                            <h2 className="blofin-aff-steps-header">STEP 7</h2>
                            <h3>Click "SUBMIT"</h3>
                            <div className='blofin-aff-guide-image'><img src='../.././assets/images/affiliate/guide/step7.png'/></div>      
                        </div>                                                                      

                    </div>
                <h1 style={{maxWidth: '80%'}}>CONGRATULATIONS YOU ARE ALL DONE!</h1>
                <h2 style={{marginTop:'2vh'}}>MAKE SURE TO STAY UP TO DATE WITH THE CRYPTO MARKETS BY JOINING OUR FREE TELEGRAM CHANNEL</h2>
                <a href="https://t.me/cryptoxnfts" target="_blank"><button className="blofin-button-dark-steps">TELEGRAM</button></a>
            </div>
                
                <div className='footer'><Footer/></div>
            </div>
        </div>
    );
};

export default BlofinGuide;