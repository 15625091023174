import { useState, useEffect } from 'react';
import './timer.css';

const TimerLead = () => {
    const calculateTimeLeft = () => {
        // Get the current time in UTC
        const now = new Date();

        // Get the first day of the next month at 00:00 UTC
        const nextMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 1));

        // Calculate the difference in milliseconds
        const timeDifference = nextMonth - now;

        // Convert milliseconds to seconds
        return Math.floor(timeDifference / 1000); // Return as seconds
    };

    // State for time left until the next month
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        // Function to update the timer every second
        const tick = () => {
            setTimeLeft(prevTime => {
                if (prevTime <= 0) {
                    return calculateTimeLeft(); // Reset the time at the next month
                }
                return prevTime - 1; // Decrease by 1 second
            });
        };

        // Set an interval to call `tick` every second
        const timerId = setInterval(tick, 1000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(timerId);
    }, []);

    // Convert timeLeft (in seconds) to days, hours, minutes, and seconds
    const days = Math.floor(timeLeft / (3600 * 24));
    const hours = Math.floor((timeLeft % (3600 * 24)) / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    // Format the timer with leading zeros
    const formatTime = (time) => (time < 10 ? `0${time}` : time);

    return (
        <div>
            <div className='timer-lead'>
                {days > 0 && `${days}D `}{formatTime(hours)}H {formatTime(minutes)}M {formatTime(seconds)}S
            </div>
        </div>
    );
};

export default TimerLead;
