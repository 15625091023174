import React, { useRef, useState, useEffect } from "react";
import './portfolio.css'
import PortfolioComp from "../../universal/portfolioComp";
import useDelayedHeightCalculation from "../../universal/useDelayedHeightCalculation";
import Footer from '../../universal/footer'

const Portfolio = ({navbarHeight}) => {

    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    const section1Height = useDelayedHeightCalculation(section1Ref, 100)

    useEffect(() => {
        setBgHeight(section1Height + navbarHeight)
    })

    return(
        <div className="main-content">
            <img 
                className='section1-bg' 
                src='.././assets/images/backgrounds/bg1.png'
                style={{ height: `${bgHeight}px` }}
                alt="Background"
            />
            <div className="port-section1" ref={section1Ref}>
                <h1>THE BEST BULL-RUN PORTFOLIO</h1>
                <PortfolioComp/>
            </div>

            <div className="port-section2">
                <h1>GET ACCESS</h1>
                <h2>Create a Bing<span style={{color: '#1068ff'}}>X</span> account & deposit 500$</h2>
                <p className="port-section2-notice">ONLY ACCOUNTS CREATED USING THE BUTTON BELOW OR WITH <span style={{color: 'red'}}>CRYPTOXNFT</span> CODE ARE VALID</p>
                <a href="https://bingx.com/en/partner/Cryptoxnft/" target="blank"><button className="button-dark">BINGX</button></a>
                <div className="proof-wrapper">
                    <h2 className="proof-header">SEND PROOF TO ADMIN</h2>
                    <a href="https://t.me/cryptoxnftsignals" target="blank"><button className="button-dark">SEND</button></a>
                </div>
            </div>
            <div className="footer"><Footer/></div>
        </div>
    )
}

export default Portfolio