import React, {useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import Footer from "../../universal/footer";
import './copyTrading.css';
import '../../universal/buttons.css'
import useDelayedHeightCalculation from "../../universal/useDelayedHeightCalculation";

const COPY = ({navbarHeight}) => {
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    const button1ref = useRef(null);
    const [button2Width, setButton2width] = useState(0);

    const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this
  
    useEffect(() => {
        setBgHeight(section1Height + navbarHeight);


        if (button1ref.current) {
            const button1width = button1ref.current.offsetWidth;
            setButton2width(button1width);
        }
    }, [navbarHeight, section1Height]);

    // Scroll to the section when the button is clicked
    const scrollToSection = (sectionRef) => {
        if (sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="main-content">
            <img src='.././assets/images/backgrounds/bg1.png'
                className='copy-section1-bg'
                style={{ height: `${bgHeight}px` }}
                alt="Background"/>

            <div className="copy-section1" ref={section1Ref}>
                <h1><span>$1,000</span> TO <span>$100,000</span> 2024 CHALLENGE</h1>
                <h2>COPY CRYPTO WHALES WITH OUR FREE COPY TRADING PROGRAM!</h2>

                <div className="copy-section1-buttons">
                    <a href="https://bingx.com/en/partner/Cryptoxnft/" target="_blank"><button className="button-light" style={{width:`${button2Width}px`}}>SIGN UP</button></a>
                    <button onClick={() => scrollToSection(section3Ref)} className="button-light" ref={button1ref}>RESULTS</button>
                </div>

                <div className="copy-section1-horizontal-line"></div>

                <div className="copy-section1-images-main">
                    <div className="copy-section1-images-left">
                        <div className="copy-section1-images-wrapper">
                            <h2>$1.5K TO $11.5K IN 90 DAYS</h2>
                            <img src=".././assets/images/services/copy/pnl.png" alt="30 day pnl"/>
                        </div>
                    </div>

                    <div className="copy-section1-images-right">
                        <div className="copy-section1-images-wrapper">
                            <h2>CURRENT BALANCE: $27,480</h2>
                            <img src=".././assets/images/services/copy/data.png" alt="current balance"/>
                        </div>        
                    </div>
                </div>
            </div>

            <div className="copy-section2" ref={section2Ref}>
                <h1>JOIN US TODAY</h1>
                <h2>- LIMITED PLACES -</h2>
                <div className="copy-section2-steps">
                    <div className="copy-step">
                        <h2 className="copy-steps-header">STEP 1</h2>
                        <h3>Create a BingX Account</h3>
                        <p>Only “cryptoxnft” promo code counts!</p>
                        <a href="https://bingx.com/en/partner/Cryptoxnft/" target="_blank"><button>CREATE ACCOUNT</button></a>
                    </div>

                    <div className="copy-step">
                        <h2 className="copy-steps-header">STEP 2</h2>
                        <h3>Download BingX App</h3>
                        <div className="copy-step-images">
                            <a href="https://apps.apple.com/pl/app/bingx-btc-eth-crypto-exchange/id1500217666" target="_blank"><img src=".././assets/images/services/copy/appstore.png" alt="Download App 1"/></a>
                            <a href="https://play.google.com/store/apps/details?id=pro.bingbon.app&hl=en_US&pli=1" target="_blank"><img src=".././assets/images/services/copy/googleplay.png" alt="Download App 2"/></a>
                        </div>      
                    </div>

                    <div className="copy-step">
                        <h2 className="copy-steps-header">STEP 3</h2>
                        <h3>Deposit minimum 100$</h3>
                    </div>

                    <div className="copy-step">
                        <h2 className="copy-steps-header">STEP 4</h2>
                        <h3>Copy our trades on BINGX</h3>
                        <Link to={'/services/copy-trading/guide'}><button>COPY TRADES</button></Link>
                    </div>
                </div>
                <h1>YOU MUST USE PROMO CODE: <span>CRYPTOXNFT</span></h1>
                <h2>Join our FREE TELEGRAM GROUP for daily insights!</h2>
                <a href="https://t.me/cryptoxnfts" target="_blank"><button>TELEGRAM</button></a>
            </div>

            <div className="copy-section3" ref={section3Ref}>
                <h1>RESULTS</h1>
                <div className="copy-section3-row">
                    <div className="copy-section3-left">
                        <div className="copy-section3-left-content">
                            <h2>30 DAY PNL: $149,926</h2>
                            <img src=".././assets/images/services/copy/profit.png" alt="image"/>
                        </div>
                    </div>
                    <div className="copy-section3-right">
                        <div className="copy-section3-right-content">
                            <h2>CURRENT BALANCE: $27,480</h2>
                            <img src=".././assets/images/services/copy/data.png" alt="image"/>
                        </div>
                    </div>
                </div>

                <div className="copy-section3-row">
                    <div className="copy-section3-left">
                        <div className="copy-section3-left-content">
                            <h2>RISK SCORE</h2>
                            <img src=".././assets/images/services/copy/risk.png" alt="image"/>
                        </div>
                    </div>
                    <div className="copy-section3-right">
                        <div className="copy-section3-right-content">
                            <h2>CUMULATIVE PNL</h2>
                            <img src=".././assets/images/services/copy/pnl.png" alt="image"/>
                        </div>
                    </div>
                </div>

                <button onClick={() => scrollToSection(section2Ref)}>SIGN UP</button>

                <div className="copy-section3-pnl-main">
                    <div className="copy-section3-pnl-row">
                        <div className="copy-section3-pnl-row-images">
                            <img src=".././assets/images/services/copy/pnl/ETH.png" alt="image"/>
                            <img src=".././assets/images/services/copy/pnl/ETH.png" alt="image"/>
                            <img src=".././assets/images/services/copy/pnl/ETH.png" alt="image"/>
                        </div>
                    </div>
                    <div className="copy-section3-pnl-row">
                        <div className="copy-section3-pnl-row-images">
                            <img src=".././assets/images/services/copy/pnl/ETH.png" alt="image"/>
                            <img src=".././assets/images/services/copy/pnl/ETH.png" alt="image"/>
                            <img src=".././assets/images/services/copy/pnl/ETH.png" alt="image"/>
                        </div>
                    </div>
                    
                    <video src=".././assets/images/services/copy/vid.MP4" loop={true} muted={true} autoPlay={true} playsInline={true} webkit-playsinline="true"/>
                    <button onClick={() => scrollToSection(section2Ref)}>SIGN UP</button>
                </div>
            </div>
            <div className="footer"><Footer/></div>
        </div>
    );
}

export default COPY;
