import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import './card.css'
import Timer from './timer'
import { FiClock } from 'react-icons/fi'
import { AiOutlineAim } from 'react-icons/ai'
import { MdTrendingUp } from 'react-icons/md'
import { FiUsers } from 'react-icons/fi'
import { HiOutlineDocumentText } from 'react-icons/hi'

const VipCard = () => {

    const [iconSize, setIconSize] = useState(22);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 769) {
                setIconSize(12);
            } else {
                setIconSize(22);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
        <div className='vipcard-main'>
            <div className='vipcard-container'>
                <h1>VIP MEMBERSHIP</h1>

                <div className='vipcard-text'>
                    <div className='paragraph one'>
                        <div className='vipcard-icon'>
                            <FiClock size={iconSize}/>
                        </div>
                        <p>Perfect Updates 24/7</p>
                    </div>
                    <div className='paragraph two'>
                        <div className="vipcard-icon">
                            <AiOutlineAim size={iconSize}/>
                        </div>
                        <p>Exact Entries and Targets</p>
                    </div>
                    <div className='paragraph three'>
                        <div className="vipcard-icon">
                            <MdTrendingUp size={iconSize}/>
                        </div>
                        <p>Spot & Futures Signals</p>
                    </div>
                    <div className='paragraph four'>
                        <div className="vipcard-icon">
                            <FiUsers size={iconSize}/>
                        </div>
                        <p>Free Copy Trading</p>
                    </div>
                    <div className='paragraph five'>
                        <div className="vipcard-icon">
                            <HiOutlineDocumentText size={iconSize}/>
                        </div>
                        <p>E-Book Access</p>
                    </div>
                </div>
                <div className='vipcard-button'>
                    <Link to="/services/vip/payment"><button className="button-vip">JOIN NOW</button></Link>
                </div>              
            </div>
        </div>
    )
}

export default VipCard