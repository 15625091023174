import React, { useEffect, useRef, useState } from "react";
import Footer from '../universal/footer'
import './risk.css'

const Risk = ({navbarHeight}) => {

    const footerRef = useRef(null);
    const [pageHeight, setPageHeight] = useState(0);

    useEffect (() => {
        if (footerRef.current) {
            const footerHeight = footerRef.current.offsetHeight;
            const windowHeight = window.innerHeight;
            setPageHeight(windowHeight - footerHeight - navbarHeight)
        }
    }, [navbarHeight])

    return (
        <div className="main-content">
            <div className="risk-bg" style={{height: `${pageHeight}px`}}></div>
            <div className="risk-main" style={{height: `${pageHeight}px`}}>
                <h1>RISK DISCLOSURE</h1>
                <p>Cryptocurrency trading involves a significant risk to your invested capital. Before deciding to participate in the cryptocurrency market, you should carefully consider your investment objectives, level of experience, and risk appetite. Most importantly, do not invest money you cannot afford to lose. CRYPTOXNFT takes no responsibility for loss incurred as a result of our trading reports. By signing up as a member, you acknowledge that we are not providing financial advice or investment plans and that you are making the decision to follow our analysis on your own account. We have no knowledge of the level of money you are trading with or the level of risk you are taking with each trade. You must make your own financial decisions.</p>
            </div>
            <div className="footer" ref={footerRef}><Footer/></div>
        </div>
    )
}

export default Risk;