import React, { useState, useEffect, useRef } from 'react';
import './faq.css';
import Footer from '../universal/footer';

const FAQ = ({ navbarHeight }) => {
  const footerRef = useRef(null);
  const windowHeight = window.innerHeight;
  const [faqPageHeight, setFaqPageHeight] = useState(0);
  const [bgHeight, setBgHeight] = useState(0);
  const faqMainRef = useRef(null);

  useEffect(() => {
    if (footerRef.current && faqMainRef) {
      const footerHeight = footerRef.current.offsetHeight;
      const faqheight = faqMainRef.current.offsetHeight;
      setFaqPageHeight(windowHeight - footerHeight - navbarHeight);
      setBgHeight(faqheight + navbarHeight);
    }
  }, [navbarHeight]);

  const [visibleQuestionIndex, setVisibleQuestionIndex] = useState(null);

  const toggleAnswerVisibility = (index) => {
    setVisibleQuestionIndex(visibleQuestionIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "Who is behind CRYPTOxNFT?",
      answer: "We are a team of 3 Crypto Maniacs whose goal is to spread our knowledge & help you become profitable."
    },
    {
      question: "What exchange do you use for trading?",
      answer: "You can follow our signals on any exchange: Binance, ByBit, etc. We recommend using BINGX Global exchange as it is the safest exchange."
    },
    {
      question: "What kind of signals do you give?",
      answer: "Everything. We mostly trade futures with leverage. The signals we give are mostly futures & a lot of economic updates that will lead to certain projects pumping tremendously."
    },
    {
      question: "What kind of coins do you tend to give signals for?",
      answer: "We trade everything from large caps, mid caps, to small caps. We trade anything and everything as long as they are good trades to take."
    },
    {
      question: "What is the win rate of your signals?",
      answer: "Usually high - around 85%. We don't give signals unless we are absolutely certain about them. Feel free to join our Free Telegram Channel & check pinned messages there."
    },
    {
      question: "How often do you give signals?",
      answer: "Usually daily. But again, we don't give signals simply for the sake of giving signals. We want to make sure they’re profitable. Just keep that in mind."
    },
    {
      question: "Do you give exact entries, take profits, and stop losses?",
      answer: "Yes."
    },
    {
      question: "What time zone are you in?",
      answer: "The signals we give are usually on the 4-hour timeframe and above, so they are not time-sensitive. Even if you live on the other side of the world, it will still be relevant. In fact, often time we even give exact limit entries, so all you have to do is place a bid at that price. And go along with your day."
    },
    {
      question: "Do you have any videos showing what your VIP looks like from the inside?",
      answer: "Yes. You can find all of them in the Free Telegram Group or on the ABOUT US page."
    }
  ];

  return (
    <div className='main-content'>
      <img 
        className='section1-bg' 
        src='./assets/images/backgrounds/bg1.png'
        style={{ height: `${bgHeight}px` }}
        alt="Background"
      />
      <div className='faq-main' ref={faqMainRef} style={{minHeight:`${faqPageHeight}px`}}>
        <h1 className='faq-header'>FAQ</h1>
        <div className='faq-content'>
          {faqData.map((item, index) => (
            <div key={index} className='faq-item'>
              <div 
                className='faq-question' 
                onClick={() => toggleAnswerVisibility(index)}
              >
                <span>{item.question}</span>
                <span className={`faq-arrow ${visibleQuestionIndex === index ? 'expanded' : ''}`}>
                  ▼
                </span>
              </div>
              <div className={`faq-answer ${visibleQuestionIndex === index ? 'expanded' : ''}`}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='footer' ref={footerRef}><Footer/></div>
    </div>
  );
}

export default FAQ;
