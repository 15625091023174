import React, {useEffect, useState, useRef} from "react";
import './vip.css'
import Footer from '../../universal/footer'
import '../../universal/buttons.css'
import useDelayedHeightCalculation from "../../universal/useDelayedHeightCalculation";
import VipCard from "../../universal/vipCard";

const VIP = ({navbarHeight}) => {

    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769); // Initial state based on window width
    const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay

    // Update bgHeight based on section1Height and navbarHeight
    useEffect(() => {
        setBgHeight(section1Height + navbarHeight);
    }, [navbarHeight, section1Height]);

    // Handle window resizing to switch between mobile/desktop text
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 769); // Update isMobile based on window size
        };

        // Set initial state and add resize listener
        handleResize();
        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
        <div className="main-content">
        <img src='.././assets/images/backgrounds/bg1.png'
            className='vip-section1-bg'
            style={{ height: `${bgHeight}px` }}
            alt="Background"/>

            <div className="vip-section1" ref={section1Ref}>
                <h1 className="vip-section1-header1">
                    {isMobile ? "CryptoxNFT VIP" : "Unlock Your VIP Access to CryptoxNFT"}
                </h1>
                <h2 className="vip-section1-subheader">
                    {isMobile ? "Maximize your success." : "Gain exclusive insights and predictions to maximize your crypto success."}
                </h2>
                <div className="vip-section1-vipcard"><VipCard/></div>
                <div className="vip-images">
                    <img src=".././assets/images/services/vip/pnl1.png" alt="pnl1"/>
                    <img src=".././assets/images/services/vip/pnl2.png" alt="pnl2" className="vip-image-middle"/>
                    <img src=".././assets/images/services/vip/pnl3.png" alt="pnl3"/>               
                </div>
            </div>

            <div className="vip-section2">
                <h1>NOT SURE WHAT TO EXPECT?</h1>
                <h2>TAKE A LOOK INSIDE OUR VIP TELEGRAM GROUP</h2>
                <div className="vip-section2-vids">
                    <video src=".././assets/images/services/vip/vid1.MOV" loop={true} muted={true} autoPlay={true} playsInline={true} webkit-playsinline="true"/>
                    <video src=".././assets/images/services/vip/vid2.MOV" loop={true} muted={true} autoPlay={true} playsInline={true} webkit-playsinline="true"/>
                </div>
            </div>

            <div className="footer"><Footer/></div>
        </div>
    )
}

export default VIP;