import { useState, useEffect, useRef } from 'react';

function useDelayedHeightCalculation(ref, delay = 100) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const calculateHeight = () => {
        const height = ref.current.offsetHeight;
        setHeight(height);
      };

      const timer = setTimeout(calculateHeight, delay);

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [ref, delay]);

  return height;
}

export default useDelayedHeightCalculation;
