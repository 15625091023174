import React, {useRef, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Footer from '../../universal/footer';
import './indicator.css'
import '../../universal/buttons.css'
import useDelayedHeightCalculation from "../../universal/useDelayedHeightCalculation";

const INDICATOR = ({navbarHeight}) => {

    const section1Ref = useRef(null);
    const [bgHeight, setBgHeight] = useState(0);

    const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this
  
    useEffect(()=> {
        setBgHeight(section1Height + navbarHeight)
    }, [navbarHeight, section1Height]);

    return(
        <div className="main-content">
            <img src='.././assets/images/backgrounds/bg1.png'
            className='indicator-section1-bg'
            style={{ height: `${bgHeight}px` }}
            alt="Background"/>
            <div className="indicator-main">
                <div className="indicator-section1" ref={section1Ref}>
                    <h1>AI INDICATOR</h1>
                    <h2>Called a <span>BUY SIGNAL</span> for <span>$SOL</span> at <span>$20</span></h2>
                    <h3>Buy it today & start profiting immediately!</h3>
                    <Link to="/services/indicator/payment"><button className="button-light">BUY NOW</button></Link>
                    <img src=".././assets/images/services/indicator/indicatorSOL.png" alt="indicator 1"/>
                </div>
                <div className="indicator-section2">

                    <div className="indicator-section2-subsection">
                        <h1>FULLY TA BASED WITH POWER OF AI</h1>
                        <h2>Get 87% accuracy using our Technical Analysis AI Indicator!</h2>
                        <div className="indicator-images">
                            <img className="ind-img" src=".././assets/images/services/indicator/indicatorOP.png" alt="indicator"/>
                            <img className="ind-pnl" src=".././assets/images/services/indicator/pnl/OP.png" alt="pnl"/>
                        </div>
                        <p>OP/USDT</p>
                    </div>

                    <div className="indicator-section2-subsection">
                        <h1>ANY PAIR & ANY TIMEFRAME</h1>
                        <h2>Our AI Indicator can be used across all timeframes and all trading pairs!</h2>
                        <div className="indicator-images">
                            <img className="ind-img" src=".././assets/images/services/indicator/indicatorSUI.png" alt="indicator"/>
                            <img className="ind-pnl" src=".././assets/images/services/indicator/pnl/SUI.png" alt="pnl"/>
                        </div>
                        <p>SUI/USDT</p>
                    </div>

                    <div className="indicator-section2-subsection">
                        <h1>BUY & SELL</h1>
                        <h2>With the power of AI, you can seamlessly open LONG or SHORT position!</h2>
                        <div className="indicator-images">
                            <img className="ind-img" src=".././assets/images/services/indicator/indicatorETH.png" alt="indicator"/>
                            <img className="ind-pnl" src=".././assets/images/services/indicator/pnl/ETH.png" alt="pnl"/>
                        </div>
                        <p>ETH/USDT</p>
                    </div>

                    <div className="indicator-section2-subsection">
                        <h1>DEVELOPED & BACKTESTED THROUGHOUT 2023</h1>
                        <h2>We spent almost a year developing and backtesting this powerful AI indicator and we are proud to introduce it to you!</h2>
                        <div className="indicator-images">
                            <img className="ind-img" src=".././assets/images/services/indicator/indicatorLINK.png" alt="indicator"/>
                            <img className="ind-pnl" src=".././assets/images/services/indicator/pnl/LINK.png" alt="pnl"/>
                        </div>
                        <p>LINK/USDT</p>
                    </div>

                    <div className="indicator-section2-bottom">
                        <h1>START PROFITING LIKE US USING AI INDICATOR</h1>
                        <div className="indicator-images-bottom">
                            <img src=".././assets/images/services/indicator/pnl/ORDI.png" alt="indicator pnl bottom"/>
                            <img src=".././assets/images/services/indicator/pnl/FET.png" alt="indicator pnl bottom" className="indicator-images-bottom2"/>
                            <img src=".././assets/images/services/indicator/pnl/VET.png" alt="indicator pnl bottom"/>
                        </div>
                        <h1>YOU ARE ONE CLICK AWAY FROM FINANCIAL FREEDOM!</h1>
                        <Link to="/services/indicator/payment"><button className="button-dark">BUY NOW</button></Link>
                    </div>

                </div>
            </div>
            <div className="footer"><Footer/></div>
        </div>
    )
}

export default INDICATOR;