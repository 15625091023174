import React from "react";
import { Link } from "react-router-dom";
import './footer.css'
import instagram from'./icons/instagram.svg'
import telegram from './icons/telegram.svg'
import tiktok from './icons/tiktok.svg'

const Footer = () => {

    const year = new Date().getFullYear();

    return(
        <div className="main-content">
            <div className="footer-main">
                <div className="footer-horizontal">
                    <div className="footer-left">
                        <div className="footer-left-text">
                            <Link to="/terms&conditions"><p className="footer-links">Terms and Conditions</p></Link>
                            <Link to="/risk-disclosure"><p className="footer-links">Risk Disclosure</p></Link>
                        </div>
                    </div>
                    <div className="footer-middle"></div>
                    <div className="footer-right">
                        <a 
                        href="https://www.instagram.com/cryptoxnftsignals/?igshid=MjEwN2IyYWYwYw%3D%3D" 
                        target="blank"><img alt="instagram" src={instagram}/></a>

                        <a href="https://t.me/cryptoxnfts"
                        target="blank"><img alt="telegram" src={telegram}/></a>

                        <a href="https://www.tiktok.com/@cryptoxnftsignals?_t=8dTzgNUZwkc&_r=1"
                        target="blank"><img alt="tiktok" src={tiktok}/></a>
                    </div>
                </div>
                <p className="footer-studio">Created by PLACEHOLDER STUDIOS</p>
                <p className="footer-copyright">© {year} All rights reserved. CRYPTOXNFT</p>
                
            </div>
        </div>
    )
}

export default Footer