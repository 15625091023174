import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../universal/footer'
import instagram from '../universal/icons/instagram.svg'
import telegram from '../universal/icons/telegram.svg'
import tiktok from '../universal/icons/tiktok.svg'
import './about.css'

function About({navbarHeight}) {
  const section1Ref = useRef(null);
  const [bgHeight, setBgHeight] = useState(0);

  useEffect(() => {
    if (section1Ref.current) {
      const section1Height = section1Ref.current.offsetHeight;
      setBgHeight(section1Height + navbarHeight);
    }
  }, [navbarHeight]);

  return (
    <div className='main-content'>
      <img src='./assets/images/backgrounds/bg1.png'
      className='section1-bg'
      style={{ height: `${bgHeight}px` }}
      alt="Background"/>

      <div className='about-section1' ref={section1Ref}>
        <h1>WELCOME TO CRYPTOXNFT</h1>
        <div className='about-section-paragraphs'>
          <p className='about-section1-p1'>
            At CryptoXNFT, we are passionate about cryptocurrencies and blockchain technology. Our expert team of analysts, traders, and blockchain specialists is dedicated to delivering the latest and most accurate industry insights. We believe cryptocurrencies have the power to revolutionize finance, and we strive to lead the way in this dynamic space, offering valuable guidance for both seasoned investors and newcomers.
          </p>
        </div>
      </div>
      <div className='about-section2'>
        <h1>We offer comprehensive content for both beginners and experienced traders.</h1>
        <p>
        We cover market analysis, trading strategies, and educational content to keep you informed and up-to-date. With a strong track record of profitability and accuracy in our signals and research, we're committed to helping you succeed in the cryptocurrency market.</p>
        <p><strong>Explore our services page to learn more about what we offer.</strong></p>
        <Link to="/services"><button>SERVICES</button></Link>
      </div>
      <div className='about-section3'>
        <h1>Thank you for visiting CRYPTOXNFT!</h1>
        <div className='about-section3-socials'>
          <div className='about-section3-socials-header'>
            <h2>Stay connected with us!</h2>
          </div>
          <div className='about-section3-socials-icons'>
            <a href='' target='blank'><img src={instagram} alt='instagram'/></a>
            <a href='' target='blank'><img src={telegram} alt='telegram'/></a>
            <a href='' target='blank'><img src={tiktok} alt='tiktok'/></a>
          </div>   
        </div>
        <p>Our team is here to support you as we embrace the future of finance together. With extensive expertise in the cryptocurrency industry, we provide valuable insights, strategies, and analyses to empower your crypto journey.</p>
        <p>Join CryptoXNFT and let us be your trusted companion in unlocking the full potential of this revolutionary industry.</p>
        <a href="https://t.me/cryptoxnfts" target="_blank" rel="noopener noreferrer">
            <button>TELEGRAM<img className='about-section3-buttin-img' src="/assets/icons/tgWhite.png"></img></button>
        </a>
      </div>
      <div className='footer'><Footer/></div>
    </div>
  );
}

export default About;
