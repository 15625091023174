import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Footer from '../universal/footer';
import '../universal/buttons.css'
import useDelayedHeightCalculation from '../universal/useDelayedHeightCalculation';

function Home({ navbarHeight }) {
  const section1Ref = useRef(null);
  const [bgHeight, setBgHeight] = useState(0);

  const div1Ref = useRef(null);
  const [div2Height, setDiv2Height] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  const divMockRef = useRef(null);
  const [mockHeight, setMockHeight] = useState(0);

  const button1Ref = useRef(null);
  const [button2Height, setButton2Height] = useState(0);
  const [button2Width, setButton2Width] = useState(0);


  const section1Height = useDelayedHeightCalculation(section1Ref, 100); // 100ms delay, you can adjust this

  useEffect(() => {
      setBgHeight(section1Height + navbarHeight);

    if (div1Ref.current) {
      const div1Height = div1Ref.current.offsetHeight;
      setDiv2Height(div1Height);
      setImgHeight((div1Height / 100) * 45);
    }

    if (divMockRef.current) {
      const divMockheight = divMockRef.current.offsetHeight;
      setMockHeight(divMockheight + 150)
    }

    if (button1Ref.current) {
      const button1Height = button1Ref.current.offsetHeight;
      const button1Width = button1Ref.current.offsetWidth;
      setButton2Height(button1Height);
      setButton2Width(button1Width);
    }
  }, [navbarHeight, section1Height]);

  return (
    <div className='main-content'>
      <img 
        className='section1-bg' 
        src='./assets/images/backgrounds/bg1.png'
        style={{ height: `${bgHeight}px` }}
        alt="Background"
      />
      <div className='section1' ref={section1Ref}>
        <h1>JOIN THE BEST VIP CRYPTO COMMUNITY</h1>
        <h2>
          Spot, Futures, Long Term Predictions & Economic Updates
        </h2>
        <div className='section1-images'>
          <img src='./assets/images/home/pnl1.png' alt="pnl1" />
          <img src='./assets/images/home/pnl2.png' alt="pnl2" />
          <img src='./assets/images/home/pnl3.png' alt="pnl3" />
        </div>
        <Link to='/services'><button className='button-light'>JOIN NOW</button></Link>
      </div>

      <div className='section2'>
        <h1 className='home-text-desktop'>Boost your Investment Knowledge with our Experienced Team</h1>
        <h1 className='home-text-mobile'>Boost your Investment Knowledge</h1>
        <p className='home-text-desktop'>Stay informed about the Crypto market with our daily updates utilizing Technical Analysis and relevant indicators to provide you with valuable insights</p>
        <h2 className='home-text-mobile'>Stay informed about the Crypto market with our daily updates.</h2>
        <div className='section2-buttons'>
          <a href="https://t.me/cryptoxnfts" target="_blank">
            <button className='button-dark' ref={button1Ref}>FREE GROUP</button>
          </a>
          <Link to='/services'><button className='button-dark' style={{height:`${button2Height}px`, width:`${button2Width}px`}}>VIP GROUP</button></Link>
        </div>
      </div>

      <div className='section3'>
        <h1>Introducing One-Click Copy Trading</h1>
        <h2>Your Automated Solution for Daily Signals on Binance, Bybit, and KuCoin!</h2>  
        <div className='section3-automated-trading'>
          <div className='section3-automated-trading-text' ref={div1Ref}>
            <p>Say goodbye to the hassle of following daily signals manually! Our innovative one-click copy trading feature handles it all for you - from entering orders to setting take profit and stop loss.</p>
            <p>Seamlessly integrated with Binance, ByBit, and KuCoin, it's the ultimate time-saving tool for busy traders seeking automated success, empowering them to capitalize on opportunities without the need for constant monitoring or manual intervention.</p>
          </div>
          <div className='section3-automated-trading-images' style={{height: `${div2Height}px`}}>
            <div className='section3-images-top'>
              <img className='section3-bybit' src='./assets/icons/bybit.png' alt='exchange logo' style={{height: `${imgHeight}px`}}/>
            </div>
            <div className='section3-images-bottom'>
              <img className='section3-kucoin' src='./assets/icons/kucoin.png' alt='exchange logo' style={{height: `${imgHeight}px`}}/>
              <img className='section3-binance' src='./assets/icons/binance.png' alt='exchange logo' style={{height: `${imgHeight}px`}}/>
            </div>
          </div>
        </div>
        <div className='section3-team'>
          <h1>Discover a team of reliable and highly experienced market analysts.</h1>
          <div className='section3-team-leftnright'>
            <div className='section3-team-right'>
              <img src='./assets/images/home/phone-mockups.png' style={{height: `${mockHeight}px`}}/>
            </div>
            <div className='section3-team-left' ref={divMockRef}>
              <p>At CRYPTOXNFT, we are committed to enhancing your knowledge and understanding of the Crypto markets</p>
              <p>Our team of skilled analysts provides top-notch reports on Crypto and markets. By leveraging our analyses, you will gain valuable insights and empower yourself to make informed investment & trading decisions.</p>
            </div>
          </div>
        </div>
        <div className='section3-cards'>
          <div className='section3-card card1'>
            <h1>Receive real-time signals on Telegram</h1>
            <p>Gain immediate access to our in-depth analysis by subscribing to our exclusive Free and Premium Telegram channels. Our comprehensive information is shared instantaneously, allowing clients worldwide to derive maximum value from our valuable insights.</p>
            <img src='./assets/images/home/pnl-proof.png' alt='' className='card1-image'/>
          </div>
          <div className='section3-card card2'>
            <h1>Let us do the heavy lifting for You</h1>
            <p>In Crypto markets, effective risk management is crucial for every investor. Unlike any other group, our analysts provide tailored Recommendations & Advice to help you navigate risk successfully.</p>
            <img src='./assets/images/home/ta-proof.png' alt='' className='card2-image'/>
          </div>
        </div>
       {/*} <div className='section-bottom-buttons'>
          <Link to="/services/vip"><button>SIGNALS</button></Link>
          <Link to="/services/indicator"><button>INDICATOR</button></Link>
          <Link to="/services/copy-trading"><button>COPY TRADING</button></Link>
        </div>*/}
        
      </div>
      <div className='footer'><Footer/></div>
    </div>
  );
}

export default Home;
